.wrapper {
  max-width: 1200px;
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
.content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.image {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 45%;
}
.text_1,
.text_2 {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 600;
  font-size: 90px;
  line-height: 133px;
  text-transform: uppercase;
}
.text_1 {
  position: absolute;
  right: 35%;
  top: -25%;
}
.text_2 {
  position: absolute;
  right: 7%;
  top: 20%;
}

.wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-indent: -25%;
}
.text {
  margin-left: 50px;
  font-family: "Oswald";
  font-style: normal;
  font-weight: 600;
  font-size: 90px;
  line-height: 133px;
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .text {
    margin-top: 0;
  }
}
@media (max-width: 820px) {
  .wrap {
    flex-direction: column;
    text-indent: 0%;
  }
  .wrap img {
    width: 75%;
    height: 75%;
    margin-left: 40px;
  }
  .text {
    margin-bottom: 20px;
    margin-left: 0px;
    text-align: center;
    font-size: 60px;
    line-height: 59px;
    margin-top: 60px;
  }
}

@media (max-width: 590px) {
  .text {
    margin-bottom: 20px;
    margin-left: 0px;
    text-align: center;
    font-size: 40px;
    line-height: 59px;
  }
}
