.wrapper {
  background-image: url("../img/main_bg.png");
  background-blend-mode: multiply;
  /* background-color: #121211; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  margin-bottom: 120px;
}
.title {
  font-family: "Oswald";
  font-weight: 500;
  font-size: 60px;
  line-height: 120%;
  color: #57f9ff;
  text-transform: uppercase;
  margin-bottom: 18px;
}
.subtitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
}
.mainButton {
  background: none;
  width: 205px;
  height: 60px;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 150%;
  font-family: "IBM Plex Mono";
  transition: 0.35s all;
  border: 1px solid #fff;
}

.mainButton:hover {
  cursor: pointer;
  color: #121212;
  background-color: #fff;
}
.loadMoreWrap {
  position: absolute;
  bottom: 30px;
  left: calc(100vw / 2 - 52px);
}
.loadText {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-transform: uppercase;
  margin-bottom: 18px;
}
.mainTextWrap {
  width: 100%;
}

@media (max-width: 992px) {
  .wrapper {
    margin-bottom: 70px;
  }
  .subtitle,
  .title {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (max-width: 590px) {
  .wrapper {
    margin-bottom: 24px;
  }
  .title {
    font-size: 32px;
    line-height: 120%;
  }
  .subtitle {
    font-size: 16px;
    line-height: 140%;
  }
}
