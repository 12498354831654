.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  width: 90%;
  height: 100%;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 60px 40px 0;
}

.item img {
  width: 280px;
}

.AU img {
  filter: invert(1);
  width: 200px;
}

.PFL img {
  width: 120px;
  max-height: 50%;
}

.RIT img {
  width: 200px;
}

.FUGAS img {
  width: 210px;
}

@media (max-width: 992px) {
  .item {
    margin: 0 50px 50px 0;
  }
  .PFL img {
    width: 120px;
  }
}

@media (max-width: 590px) {
  .wrapper {
    margin-top: 20px;
  }
  .item img {
    width: 220px;
  }
  .item {
    width: 100%;
    margin: 0 0 50px 0;
  }
  .item:last-child {
    margin: 0;
  }

  .PFL img {
    width: 150px;
  }
}
