.wrapper {
  max-width: 1200px;
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
.itemsRow {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
.item {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid #fff;
  margin-right: 40px;
  min-height: 600px;
  transition: all 0.25s;
}
.itemLink {
  color: #fff;
  text-decoration: none;
  min-height: 600px;
}
.item:last-child {
  margin-right: 0px;
}
.item:has(.itemLink):hover, .item:has(.itemLink):hover .itemLink {
  background-color: #fff;
  color: #000;
}
.itemLink:hover {
  color: #000;
}
.item:has(.itemLink):hover .tagsWrap,
.item:has(.itemLink):hover .tagsWrap svg {
  color: #3a3a3a;
}

.image {
  width: 100%;
  height: 100%;
  min-height: 100px;
}
.itemText {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
  margin-top: 25px;
}
.title {
  font-family: "Audiowide";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 150%;
  text-transform: uppercase;
  margin: 0;
}
.description {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  margin: 0;
}
.mainButton {
  background: none;
  width: 205px;
  height: 60px;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 150%;
  font-family: "IBM Plex Mono";
  transition: 0.35s all;
  border: 1px solid #fff;
}
.mainButton:hover {
  cursor: pointer;
  color: #121212;
  background-color: #fff;
}

.tagsWrap {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #b4b4b4;
  font-size: 14px;
}
.tagsWrap svg {
  color: #b4b4b4;
  margin-right: 20px;
}

.badges {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 30px;
}

.badge {
  width: 100%;
  height: 100%;
  max-width: 180px;
  min-width: 180px;
}

.customBadge {
  max-width: 180px;
  min-width: 180px;
  background: none;
  width: 205px;
  height: 50px;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 150%;
  font-family: "IBM Plex Mono";
  transition: 0.35s all;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.customBadge:hover {
  color: #121212;
  background-color: #fff;
}

@media (max-width: 992px) {
  .itemsRow {
    flex-direction: column;
    margin-bottom: 0px;
  }
  .item {
    margin-bottom: 24px;
    margin-right: 0;
    min-height: 0px;
  }
  .itemLink {
    min-height: 0px;
  }
  .image {
    max-height: 350px;
  }
}
@media (max-width: 590px) {
  .title {
    font-size: 20px;
    line-height: 150%;
  }
  .description {
    font-size: 16px;
    line-height: 140%;
  }
}
