.container {
  margin-top: 85px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  width: 90%;
  height: 100%;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
}
.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.content {
  padding: 35px 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* height: 320px; */
}
.tabsRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.tabsColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 55px;
}
.tab {
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-decoration: none;
  color: #fff;
  transition: 0.2s all;
  padding-right: 24px;
  padding-left: 8px;
  position: relative;
}
.tab:hover {
  color: #000;
}
.tab:hover::after {
  display: block;
  animation: fade-out 0.2s;
  background-color: #57f9ff;
  clip-path: polygon(0% 0%, 100% 0%, calc(100% - 24px) 100%, 0% 100%);
}
.tab::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.infoPart {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}
.infoRow {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.contactsWrap {
  margin-left: 10%;
}
.linksTitle {
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  margin-top: 60px;
}
.linksRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 12px;
}
.link {
  margin-right: 12px;
  transition: 0.35s all;
}
.link a,
.link,
.link img {
  text-decoration: none !important;
}
.link:last-child {
  margin-right: 0px;
}

.link:hover {
  cursor: pointer;
  background-color: #fff;
}
.link:hover img {
  filter: invert(1);
}

.copyrightText {
  text-align: center;
  padding: 0px 20px;
  color: #b4b4b4;
  font-size: 14px;
}

@keyframes fade-out {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@media (max-width: 1280px) {
  .tabsRow {
    flex-wrap: wrap;
  }
}
@media (max-width: 992px) {
  .footerLogo img {
    width: 100%;
  }
  .infoPart { 
    width: 65%;
  }
  .infoRow {
    flex-direction: column-reverse;
  }
  .contactsWrap {
    margin-left: 0;
  }
  .linksTitle {
    margin-top: 20px;
  }
}

@media (max-width: 789px) {
  .infoRow {
    justify-content: center;
    align-items: center;
  }
  .linksTitle {
    margin-top: 60px;
  }
  .wrapper {
    margin-top: 40px;
  }
  .footerLogo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .footerLogo img {
    width: 50%;
  }
  .content {
    flex-direction: column;
    padding: 0px;
    padding-top: 34px;
    padding-bottom: 34px;
  }
  .tabsRow {
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
  }
  .infoPart {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .tabsColumn {
    margin-right: 0px;
    align-items: center;
  }
  .tab {
    margin-bottom: 24px;
  }
  .linksTitle {
    margin-top: 32px;
    text-align: center;
  }
  .linksRow {
    justify-content: center;
    width: 100%;
  }
}

@media (max-width: 590px) { 
  .footerLogo img {
    width: 80%;
  }
  .link {
    margin-right: 6px;
  }
}

@media only screen and (max-width: 992px) {
  .tab {
    padding: 0px;
  }
  .tab:hover {
    color: #fff;
  }
  .tab:hover::after{
    animation: none;
    display: none;
  }
}

/* Extra small devices (phones, 600px and down) */
/* @media only screen and (max-width: 600px) {...} */

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {...} */

/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {...} */

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {...} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {...}  */
