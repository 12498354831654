.wrapper {
  width: 90%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
}
.row {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
}
.item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  border: 1px solid #fff;
  width: 100%;
  height: 100%;
  min-height: 475px;
  margin-right: 40px;
}
.item:last-child,
.row:last-child {
  margin-right: 0px;
  margin-bottom: 0px;
}
.itemTextWrapper {
  text-align: left;
  padding: 25px 40px 20px 20px;
}
.itemName {
  font-weight: 400;
  font-size: 26px;
  line-height: 42px;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 6px;
}
.itemDescription,
.itemPosition {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 10px;
}
.itemDescription {
  text-transform: none;
}
.socialButton {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-left: 1px solid #fff;
  border-top: 1px solid #fff;
  bottom: 0;
  right: 0;
  transition: 0.35s all;
}
.socialButton:hover {
  cursor: pointer;
  background-color: #fff;
}
.socialButton:hover img {
  filter: invert(1);
}

.memberImage {
  width: 100%;
}

@media (max-width: 1200px) {
  .item {
    margin-right: 20px;
  }
  .itemName {
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .row {
    flex-direction: column;
    margin-bottom: 20px;
  }
  .item {
    margin-right: 0px;
    margin-bottom: 20px;
    max-width: 400px;
    min-height: 100%;
  }
  .memberImage {
    width: 100%;
  }
  .itemTextWrapper {
    width: 75%;
  }
}

@media (max-width: 590px) {
  .wrapper {
    margin-bottom: 40px;
  }
  .itemName {
    font-size: 20px;
    line-height: 26px;
  }
  .itemPosition {
    font-size: 12px;
    line-height: 140%;
  }
  .itemDescription {
    font-size: 16px;
    line-height: 140%;
  }
}

@media (max-width: 380px) {
  .itemTextWrapper {
    width: 70%;
  }
}