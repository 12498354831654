@import url("https://fonts.googleapis.com/css2?family=Audiowide&family=Bebas+Neue&family=IBM+Plex+Mono&family=Oswald:wght@400;500;600&display=swap");
html {
  scroll-behavior: smooth;
}
div {
  scroll-margin-top: 100px;
}
body {
  margin: 0;
  font-family: "IBM Plex Mono";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121211;
  color: #fff;
  font-weight: 400;
  position: relative;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar {
  width: 0px;
}

.language-link {
  font-size: 20px !important;
  margin-left: 8px !important;
}