.wrapper {
  display: flex;
  justify-content: flex-start;
  width: 90%;
  max-width: 1200px;
  margin-bottom: 70px;
}
.title {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 120%;
  text-transform: uppercase;
  margin: 0;
}
.border {
  width: 100%;
  background-color: #57f9ff;
  height: 10px;
  position: relative;
  margin-top: 20px;
  clip-path: polygon(15px 0%, 100% 0%, calc(100% - 15px) 100%, 0% 100%);
  transition: 0.35s all;
}
.title:hover .border {
  background-color: #fff;
}

@media (max-width: 590px) {
  .wrapper {
    justify-content: center;
    margin-bottom: 24px;
  }
  .title {
    font-size: 40px;
    line-height: 120%;
  }
  .border {
    margin-top: 12px;
  }
}
