.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}
.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #121211;
  height: 100px;
}
.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  max-width: 1200px;
  max-width: 1200px;
  width: 90%;
}
@keyframes logoChangable {
  0% {
    background-image: url("../img/LogoCats/Purple.png");
  }
  20% {
    background-image: url("../img/LogoCats/Blue.png");
  }
  40% {
    background-image: url("../img/LogoCats/Orange.png");
  }
  60% {
    background-image: url("../img/LogoCats/Yellow.png");
  }
  80% {
    background-image: url("../img/LogoCats/Green.png");
  }
  100% {
    background-image: url("../img/LogoCats/Purple.png");
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes hover-link {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.linksWrap {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.linksList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}
.logoImage {
  width: 69.97px;
  height: 57.61px;
  animation: logoChangable 10s infinite;
  position: relative;
  background-position: center;
  background-attachment: scroll;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
}
.logoImage p {
  position: absolute;
  top: -10px;
  right: -5px;
  font-family: "Oswald";
  font-weight: 500;
  font-size: 9.91048px;
  line-height: 15px;
  text-align: left;
}

.biggerLogoText {
  font-size: 13.9296px !important;
  line-height: 10px !important;
}

.mainLink {
  color: #fff;
  text-decoration: none;
}

.fullMenu {
  width: 100%;
  height: 600px;
  background-color: #121211;
  border-top: 2px solid #fff;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  animation: fade-in 0.5s;
}
.link {
  z-index: 1;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  text-decoration: none;
  color: #fff;
  transition: 0.15s all;
  padding-right: 32px;
  padding-left: 8px;
  position: relative;
}
.fullMenu .link {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 26px;
}
.link:hover {
  color: #000;
  cursor: pointer;
}
.link:hover::after {
  display: block;
  animation: hover-link 0.2s;
  background-color: #57f9ff;
  clip-path: polygon(0% 0%, 100% 0%, calc(100% - 24px) 100%, 0% 100%);
}
.link::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.rightColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.linksWrapper {
  width: 100%;
  height: 100%;
}
.linksTitle {
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  margin-top: 28px;
  color: #f6a32b;
  text-align: center;
}
.linksColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.linksRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
}
.socialLink {
  margin-right: 12px;
}
.socialLink a,
.socialLink,
.socialLink img {
  text-decoration: none !important;
}
.socialLink:last-child {
  margin-right: 0px;
}
.socialLink:hover {
  cursor: pointer;
  background-color: #fff;
}
.socialLink:hover img {
  filter: invert(1);
}
.fullMenuLogo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.fullMenuLogo img {
  width: 100%;
  height: 100%;
  max-width: 300px;
}

@media (max-width: 992px) {
  .linksWrap {
    display: none;
  }
}

@media (max-width: 600px) {
  .content {
    justify-content: space-between;
  }
}

@media (max-width: 500px) {
  .fullMenu {
    flex-direction: column;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .fullMenu .link {
    margin-bottom: 8px;
    font-size: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .fullMenuLogo {
    width: 60%;
  }
  .rightColumn {
    align-items: center;
  }
  .linksTitle {
    font-size: 18px;
    line-height: 140%;
  }
  .linksColumn {
    align-items: center;
    margin-left: 24px;
  }
}

.select .MuiInput-underline::before,
.select .MuiInput-underline::after {
  display: none;
}

.select {
  color: #fff !important;
  /* background-color: #fff; */
}
.select svg {
  filter: invert(1);
}
.select fieldset {
  border: none !important;
}
.buttons {
  display: flex;
  align-items: center;
}
