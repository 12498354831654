.wrapper {
  width: 90%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
.titleTextWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 40px;
}
.leftColumn {
  width: 100%;
  margin-right: 40px;
}
.rightColumn {
  width: 100%;
  margin-left: 40px;
}
.smallTitle {
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin: 0;
}
.textBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.timerText {
  color: #57f9ff;
  font-family: "Oswald";
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
}
.timerIcon {
  margin-right: 24px;
}
.topRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-bottom: 40px;
}
.midRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.bottomRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  height: 100%;
}

/* Stat line */
.statWrapper {
  border: 1px solid #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 75px;
  padding: 0;
  margin-bottom: 40px;
}
.statWrapper:last-child {
  margin-bottom: 0px;
}
.indexWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #fff;
  padding-left: 12px;
  padding-right: 12px;
}
.indexText {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #121211;
}
.titleWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-left: 24px;
}
.titleText {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
}

.mainStatContainer {
  border: 1px solid #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  margin-bottom: 40px;
}
.mainStatContainer .indexWrapper {
  height: 230px;
}
.mainStatRowWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.mainStatWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
}
.mainStatItemWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 27px;
  padding-bottom: 27px;
}
.mainStatItemTitle {
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin: 0;
}
.mainStatItemValue {
  font-family: "Oswald";
  font-size: 60px;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  color: #57f9ff;
  margin: 0;
}

.statImage {
  width: 100%;
  height: 500px;
}

.statImage_1 {
  background-image: url("../img/stats_1.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.statImage_2 {
  background-image: url("../img/stats_2.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 665px;
  width: 66%;
}

@media (max-width: 1280px) {
  .statImage_2 {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .statImage_1 {
    background-size: cover;
  }
}

@media (max-width: 992px) {
  .statImage {
    display: none;
  }
  .topRow,
  .mainStatContainer,
  .bottomRow {
    flex-direction: column;
    margin-bottom: 24px;
  }
  .topRow img,
  .bottomRow img {
    display: none;
  }
  .mainStatContainer .indexWrapper {
    width: 100%;
    padding: 0px;
    height: 100%;
  }
  .mainStatContainer .indexText {
    font-size: 20px !important;
  }
  .mainStatItemTitle {
    font-size: 24px;
    line-height: 26px;
  }
  .mainStatItemValue {
    font-size: 80px;
    line-height: 150%;
  }
  .mainStatWrapper {
    flex-wrap: wrap;
  }
  .rightColumn {
    margin-left: 0;
  }
  .leftColumn {
    margin-right: 0;
  }
  .timerIcon {
    margin-right: 16px;
  }
  .timerIcon img {
    display: block;
  }
  .smallTitle {
    font-size: 20px;
    line-height: 21px;
  }
  .titleWrapper {
    padding-left: 14px;
  }
  .statWrapper {
    margin-bottom: 30px;
    height: 70px;
  }
  .titleTextWrapper {
    margin-bottom: 24px;
  }
}

@media (max-width: 590px) {
  .indexText {
    font-size: 14px;
  }
  .mainStatContainer .indexText {
    font-size: 16px !important;
  }
  .mainStatItemTitle {
    font-size: 20px;
    line-height: 26px;
  }
  .mainStatItemValue {
    font-size: 60px;
    line-height: 150%;
  }
  .smallTitle {
    font-size: 16px;
    line-height: 21px;
  }
  .timerText {
    font-size: 32px;
    line-height: 150%;
  }
  .titleText {
    font-size: 16px;
    line-height: 16px;
  }
  .statWrapper {
    margin-bottom: 16px;
    height: 70px;
  }
}
